import Link from '@swe/shared/providers/router/link';
import { Trans } from '@swe/shared/tools/intl/components/trans';
import { useBreakpoint } from '@swe/shared/tools/media';
import Button from '@swe/shared/ui-kit/components/button';
import Loader from '@swe/shared/ui-kit/components/loader';
import { SectionHeading } from '@swe/shared/ui-kit/components/section-heading';
import Stack from '@swe/shared/ui-kit/components/stack';

import Text from '@swe/shared/ui-kit/components/text';

import { useCallback } from 'react';

import styles from './styles.module.scss';

import { useCurrentUser } from 'common/providers/user';
import { Routes } from 'common/router/constants';

const Profile = () => {
  const { user, redirectUnauthorized } = useCurrentUser();
  const { lessThan } = useBreakpoint();
  const onClickHandler = useCallback(() => redirectUnauthorized(), [redirectUnauthorized]);

  const SingUpButton = (
    <Button
      className={lessThan.sm ? undefined : styles.signUp}
      onClick={onClickHandler}
      name="finish"
    >
      Finish Sign Up
    </Button>
  );

  if (!user) {
    return (
      <Loader
        size="lg"
        centered
      />
    );
  }

  return (
    <Stack spacing="xs">
      <SectionHeading
        size="lg"
        color="ghost"
        subHeading={
          <Stack spacing="md">
            <Text
              size="lg"
              adaptive
            >
              <Trans
                id="domains.profile.nonComplete.text1"
                defaultMessage="As a guest you’re limited with Profile actions, including loyalty program. Sign up or log in into your account to unlock all benefits."
              />
            </Text>
            <Text
              size="lg"
              adaptive
            >
              <Trans
                id="domains.profile.nonComplete.text2"
                defaultMessage="Subscribing to our mailing list will allow you to earn loyalty points with every purchase and use them to get more discounts for your future orders"
              />
            </Text>
            <Link href={Routes.ProfileLoyalty}>
              <Trans
                id="domains.profile.loyalty.termsLink"
                defaultMessage="Loyalty program terms"
              />
            </Link>
            {!lessThan.sm && SingUpButton}
          </Stack>
        }
      >
        Full member benefits
      </SectionHeading>
      {lessThan.sm && SingUpButton}
    </Stack>
  );
};

export { Profile };
