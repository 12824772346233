import {
  NotificationsIcon,
  ChevronRightIcon,
  DocumentsIcon,
  PrivacyIcon,
  DiscountIcon,
  CartIcon,
  UserIcon,
} from '@swe/shared/ui-kit/components/icon';
import { SectionHeading, SectionHeadingProps } from '@swe/shared/ui-kit/components/section-heading';
import Stack from '@swe/shared/ui-kit/components/stack';

import { useMemo } from 'react';

import { isStateOf } from '@swe/shop-ui/entities/shop/info';
import ReferralBanner from 'common/containers/referral-banner';
import { useStoreConfig } from 'common/providers/config';
import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

import { useStoreInfo } from 'common/use-cases/use-store-info';
import { OrderCard } from 'domains/profile/components/order-card';
import { LoyaltyWidget } from 'domains/profile/containers/loyalty/container/loyalty-widget';
import { useOrdersHistory } from 'domains/profile/use-cases/use-orders-history';

const Profile = () => {
  const navigate = useRouterNavigate();
  const { user } = useCurrentUser();
  const { lastNonCompleted } = useOrdersHistory();
  const store = useStoreInfo();
  const isCalifornia = !!store && isStateOf(store, 'California');
  const { isTruncatedShop } = useStoreConfig();

  const settings = useMemo<SectionHeadingProps[]>(
    () => [
      ...(!isTruncatedShop
        ? ([
            {
              icon: CartIcon,
              children: 'Order History',
              trailIcon: ChevronRightIcon,
              onClick: () => {
                return navigate(Routes.ProfileOrders);
              },
            },
            {
              icon: DiscountIcon,
              children: 'Promo Codes',
              trailIcon: ChevronRightIcon,
              onClick: () => {
                return navigate(Routes.ProfilePromo);
              },
            },
            {
              icon: DocumentsIcon,
              children: 'Documents',
              trailIcon: ChevronRightIcon,
              badge: user?.isActionRequired
                ? {
                    children: 'Action required',
                    color: 'danger',
                  }
                : undefined,
              onClick: () => {
                return navigate(Routes.ProfileDocuments);
              },
            },
          ] as SectionHeadingProps[])
        : []),
      {
        icon: UserIcon,
        children: 'Contact Information',
        trailIcon: ChevronRightIcon,
        onClick: () => {
          return navigate(Routes.ProfileContacts);
        },
      },
      {
        icon: NotificationsIcon,
        children: 'Notifications',
        trailIcon: ChevronRightIcon,
        onClick: () => {
          return navigate(Routes.ProfileNotifications);
        },
      },
      ...(isCalifornia || !user?.hasPassword
        ? [
            {
              icon: PrivacyIcon,
              children: 'Privacy & Security',
              trailIcon: ChevronRightIcon,
              onClick: () => {
                return navigate(Routes.ProfilePrivacy);
              },
            },
          ]
        : []),
    ],
    [isTruncatedShop, user?.isActionRequired, user?.hasPassword, isCalifornia, navigate],
  );

  return (
    <Stack spacing="sm">
      {lastNonCompleted && <OrderCard order={lastNonCompleted} />}
      <LoyaltyWidget />
      {!isTruncatedShop && <ReferralBanner size="md" />}
      <Stack
        divider
        spacing="none"
      >
        {settings.map((setting, idx) => (
          <SectionHeading
            key={idx}
            {...setting}
            size="md"
          />
        ))}
      </Stack>
    </Stack>
  );
};

export { Profile };
