import Image from '@swe/shared/ui-kit/components/image';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { getTokenValue } from '@swe/shared/ui-kit/theme/provider/helpers';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';
import { forwardRef, useEffect, useMemo, useRef } from 'react';

import styles from './styles.module.scss';

type ArcProps = {
  color: 'background' | 'active';
  width: number;
  height: number;
};

const Arc = forwardRef<SVGPathElement, ArcProps>(({ color = 'background', width, height }, ref) => {
  const theme = useTheme();
  const strokeWidth = parseInt(theme.loyalty.gauge.border[color].width, 10);

  return (
    <path
      ref={ref}
      className={cx(styles.arc, styles[`arc_color_${color}`])}
      d={`M 0 ${height} A ${width / 2} ${height * 0.85} 0 0 1 ${width} ${height}`}
      strokeWidth={strokeWidth}
      fill="none"
    />
  );
});

type LoyaltyProgressBarProps = ComponentHasClassName & {
  value: number;
  logo?: AbsoluteURL;
};

const LoyaltyProgressBar = ({ className, logo, value }: LoyaltyProgressBarProps) => {
  const progressArcRef = useRef<SVGPathElement>(null);
  const normalizedProgress = useMemo(() => Math.min(Math.max(0, value), 1), [value]);
  const theme = useTheme();

  const width = parseInt(theme.loyalty.gauge.maxWidth, 10);
  const height = parseInt(theme.loyalty.gauge.maxHeight, 10);

  useEffect(() => {
    const path = progressArcRef.current;
    if (!path) return;

    const totalLength = path.getTotalLength();
    const realLength = totalLength * normalizedProgress;
    path.style.transition = 'none';
    path.style.strokeDasharray = `${realLength} ${totalLength}`;
    path.style.strokeDashoffset = realLength.toString();
    path.getBoundingClientRect();
    path.style.transition = 'stroke-dashoffset 2s ease-in-out';
    path.style.strokeDashoffset = '0';
  }, [normalizedProgress]);

  return (
    <div className={cx(className, styles.root)}>
      <Image
        alt="Loyalty Logo"
        className={styles.logo}
        src={logo}
        ratio={1}
        width={getTokenValue(theme.loyalty.logo.maxWidth)}
      />
      <svg
        width={width}
        height={height}
        className={styles.canvas}
      >
        <Arc
          color="background"
          width={width}
          height={height}
        />
        <Arc
          ref={progressArcRef}
          color="active"
          width={width}
          height={height}
        />
      </svg>
    </div>
  );
};

export type { LoyaltyProgressBarProps };
export { LoyaltyProgressBar };
