import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from 'domains/profile/containers/loyalty/components/loyalty-widget/styles.module.scss';

type WidgetRightPanelProps = ComponentHasClassName & {
  hasNextLevel?: boolean;
  status?: string;
};

const WidgetRightPanel = ({ className, hasNextLevel, status }: WidgetRightPanelProps) => {
  return (
    <div className={className}>
      {hasNextLevel ? 'Keep\u00A0going!' : 'Hooray! Highest\u00A0level!'}
      {status && <div className={styles.title}>{status}</div>}
    </div>
  );
};

export type { WidgetRightPanelProps };
export { WidgetRightPanel };
