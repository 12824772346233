import Link from '@swe/shared/providers/router/link';
import Section from '@swe/shared/ui-kit/components/section';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';
import Stack from '@swe/shared/ui-kit/components/stack';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { ReactNode } from 'react';

import styles from './styles.module.scss';

import { Routes } from 'common/router/constants';
import { Requirement } from 'entities/loyalty/info';

const REQUIREMENT_TO_TEXT: Record<Requirement, string> = {
  [Requirement.Email]: 'Provide your email',
  [Requirement.Phone]: 'Provide your phone number',
  [Requirement.NotificationSubscription]: 'Subscribe to marketing communications',
};

const REQUIREMENT_TO_LINK: Record<Requirement, ReactNode> = {
  [Requirement.Email]: (
    <Link
      className={styles.link}
      href={Routes.ProfileContacts}
    >
      Go to Profile Contact Information
    </Link>
  ),
  [Requirement.Phone]: (
    <Link
      className={styles.link}
      href={Routes.ProfilePhoneUpdate}
    >
      Go to Profile Contact Information
    </Link>
  ),
  [Requirement.NotificationSubscription]: (
    <Link
      className={styles.link}
      href={Routes.ProfileNotifications}
    >
      Go to Profile Notifications
    </Link>
  ),
};

type RequirementsProps = ComponentHasClassName & {
  requirements: Requirement[];
};

const Requirements = ({ className, requirements }: RequirementsProps) => {
  if (requirements.length === 0) return null;

  return (
    <Section
      heading={{ children: 'Required steps' }}
      expandable
    >
      <Stack
        className={className}
        spacing="sm"
      >
        {requirements.map((requirement) => (
          <div key={requirement}>
            <SectionHeading
              bullet=""
              size="sm"
            >
              {REQUIREMENT_TO_TEXT[requirement]}
            </SectionHeading>
            {REQUIREMENT_TO_LINK[requirement]}
          </div>
        ))}
      </Stack>
    </Section>
  );
};

export type { RequirementsProps };
export { Requirements };
