import { useCurrentUser } from 'common/providers/user';
import { Profile as ProfileComplete } from 'domains/profile/containers/profile/complete-profile';
import { Profile as ProfileNonComplete } from 'domains/profile/containers/profile/non-complete-profile';

const Profile = () => {
  const { isRegistrationComplete } = useCurrentUser();

  return isRegistrationComplete ? <ProfileComplete /> : <ProfileNonComplete />;
};

export { Profile };
