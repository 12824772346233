import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { formatDistanceToNow } from '@swe/shared/utils/date';
import cx from 'clsx';

import styles from './styles.module.scss';

import { ExpirationData, ExpirationType } from 'entities/loyalty/info';

type PointsExpirationProps = ComponentHasClassName & {
  data: ExpirationData;
};

const formatDistance = (dateString: DateISOString, addSuffix = true) => {
  const date = new Date(dateString);
  if (addSuffix && date <= new Date()) {
    return 'soon';
  }
  return formatDistanceToNow(date, { addSuffix, includeSeconds: false });
};

const PointsExpiration = ({ className, data }: PointsExpirationProps) => {
  if (!data) {
    return null;
  }

  let totalMessage = '';
  if (data.totalExpirationPoints > 0) {
    totalMessage = `${data.totalExpirationPoints} points will expire ${formatDistance(data.totalExpirationDate)}`;
    if (data.expirationType === ExpirationType.RollingDateFromLastOrder && data.lastOrderDate) {
      totalMessage += ` because you have not made a purchase in the last ${formatDistance(
        data.lastOrderDate,
        false,
      )}. Come shop with us to prevent your points from expiring!`;
    }
  }

  const nearestMessage = data.nearestExpirationDate
    ? `The nearest expiration date for ${data.nearestExpirationPoints} points is ${formatDistance(
        data.nearestExpirationDate,
      )}`
    : '';

  return (
    <div className={cx(className, styles.root)}>
      {totalMessage && <div>{totalMessage}</div>}
      {nearestMessage && (
        <div>
          <br />
          {nearestMessage}
        </div>
      )}
    </div>
  );
};

export type { PointsExpirationProps };
export { PointsExpiration };
