/* eslint-disable no-bitwise */
import Price from '@swe/shared/ui-kit/components/price';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { formatPoints } from '@swe/shared/utils/points';
import cx from 'clsx';
import { ReactNode } from 'react';

import styles from './styles.module.scss';

type PropertyProps = ComponentHasClassName & {
  main: ReactNode;
  support: ReactNode;
};

const Property = ({ className, main, support }: PropertyProps) => (
  <div className={cx(className, styles.statusProperty)}>
    <div className={styles.line} />
    <div className={styles.propertyContainer}>
      {support !== undefined ? <div className={styles.supportValue}>{support}</div> : null}
      <div className={styles.mainValue}>{main}</div>
    </div>
  </div>
);

type StatusAndPointsProps = ComponentHasClassName & {
  earnedAmount: number;
  pointToMoneyRate: number;
  currentStatus?: string;
  currentYear: number;
  hideCurrency?: boolean;
};

const StatusAndPoints = ({
  className,
  earnedAmount,
  currentYear,
  pointToMoneyRate,
  currentStatus,
  hideCurrency,
}: StatusAndPointsProps) => {
  return (
    <div className={className}>
      <div className={styles.sp}>
        <Property
          main={currentStatus ?? 'Zero'}
          support={`${currentYear} Status`}
        />
        <Property
          main={formatPoints(earnedAmount)}
          support={hideCurrency ? undefined : <Price value={earnedAmount * pointToMoneyRate} />}
        />
      </div>
    </div>
  );
};

export type { StatusAndPointsProps };
export { StatusAndPoints };
