import Link from '@swe/shared/providers/router/link';
import { Trans } from '@swe/shared/tools/intl/components/trans';

import styles from './styles.module.scss';

import { Routes } from 'common/router/constants';

const TermsLink = () => (
  <Link
    className={styles.root}
    href={Routes.ProfileLoyalty}
  >
    <Trans
      id="domains.profile.loyalty.termsLink"
      defaultMessage="Loyalty program terms"
    />
  </Link>
);

export { TermsLink };
