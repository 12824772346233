import { LogoutIcon } from '@swe/shared/ui-kit/components/icon';

import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { useCurrentUser } from 'common/providers/user';
import { Profile } from 'domains/profile/containers/profile';
import { getFullName } from 'entities/authorization/user';

const TITLE = 'Profile';

const ProfileIndexPage: ApplicationPage = () => {
  const { user, logout } = useCurrentUser();

  return (
    <Page
      {...DEFAULT_AUTHORIZED_PAGE_PROPS}
      header={{
        title: user ? getFullName(user, TITLE) : TITLE,
        hideBackButton: true,
        breadcrumbs: [{ text: TITLE }],
      }}
      action={{ onClick: () => logout(), children: 'Log out', name: 'log-out', icon: LogoutIcon }}
    >
      <Profile />
    </Page>
  );
};

ProfileIndexPage.getMeta = () => ({ title: TITLE });

ProfileIndexPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ProfileIndexPage;
