import Skeleton, { Bone } from '@swe/shared/ui-kit/components/skeleton';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';

const LoyaltyProgressSkeleton = () => {
  const theme = useTheme();

  return (
    <Skeleton
      uniqueKey="loyalty_progress_skeleton"
      height={325}
    >
      <Bone
        x="0"
        y="0"
        rx={theme.borderRadius.scale.lg}
        ry={theme.borderRadius.scale.lg}
        width="100%"
        height="100%"
      />
    </Skeleton>
  );
};

export { LoyaltyProgressSkeleton };
