import { Trans } from '@swe/shared/tools/intl/components/trans';
import Alert from '@swe/shared/ui-kit/components/alert';
import { InfoIcon } from '@swe/shared/ui-kit/components/icon';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';
import Stack from '@swe/shared/ui-kit/components/stack';
import cx from 'clsx';

import { useCallback } from 'react';

import styles from './styles.module.scss';

import { useStoreConfig } from '@swe/shop-ui/common/providers/config';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';
import { useLoyalty } from 'common/use-cases/use-loyalty';
import { LoyaltyWidgetSkeletonized as DumbLoyaltyWidget } from 'domains/profile/containers/loyalty/components/loyalty-widget';
import { Requirements } from 'domains/profile/containers/loyalty/components/requirements';
import { RequirementsText } from 'domains/profile/containers/loyalty/components/requirements-text';
import { TermsLink } from 'domains/profile/containers/loyalty/components/terms-link';
import { Status } from 'entities/loyalty/info';

const LoyaltyNotAvailableAtLocation = () => (
  <Alert color="neutral">
    <Trans
      id="domains.profile.loyalty.widget.notAvailableAtLocation"
      defaultMessage="Loyalty program is not available at this location"
    />
  </Alert>
);

const LoyaltyWidget = () => {
  const { isEnabled, isEnabledAtStore, isHidden, status, requirements, loyalty, settings, isLoading } = useLoyalty();
  const navigate = useRouterNavigate();
  const handleLoyaltyInfoClick = useCallback(() => navigate(Routes.ProfileLoyalty), [navigate]);

  const { hideLoyaltyCurrency } = useStoreConfig();

  if (isEnabled === false) {
    return null;
  }

  if (status === Status.NonMember) {
    return (
      <SectionHeading
        size="lg"
        color="ghost"
        noPadding
        subHeading={
          <Stack spacing="md">
            <RequirementsText
              size="lg"
              requirements={loyalty?.requirements}
            />
            <Stack spacing="xxs">
              <TermsLink />
              <Requirements requirements={requirements} />
            </Stack>
          </Stack>
        }
        button={{
          children: '',
          color: 'light',
          icon: InfoIcon,
          onClick: handleLoyaltyInfoClick,
        }}
      >
        Join the Members club!
      </SectionHeading>
    );
  }

  const Widget = (
    <Stack
      className={cx(isEnabledAtStore === false && styles.loyaltyWidget_disabled)}
      spacing="sm"
    >
      {status !== Status.Member && !isLoading && (
        <SectionHeading
          size="lg"
          button={{ color: 'light', icon: InfoIcon, onClick: handleLoyaltyInfoClick }}
        >
          <Trans
            id="domains.profile.loyalty.widget.inactive.title"
            defaultMessage="Loyalty program is inactive"
          />
        </SectionHeading>
      )}
      <DumbLoyaltyWidget
        status={status}
        source={loyalty}
        settings={settings}
        onInfoButtonClick={handleLoyaltyInfoClick}
        hideCurrency={hideLoyaltyCurrency}
      />
      {status !== Status.Member && !isLoading && (
        <>
          <Alert color="warning">
            <Trans
              id="domains.profile.loyalty.widget.inactive.alert"
              defaultMessage="Please consider to subscribe to our marketing messages in your Notifications to activate the Loyalty program"
            />
          </Alert>
          <Requirements requirements={requirements} />
        </>
      )}
    </Stack>
  );

  if (isEnabledAtStore === false) {
    if (isHidden) {
      return <LoyaltyNotAvailableAtLocation />;
    }

    return (
      <Stack>
        {Widget}
        <LoyaltyNotAvailableAtLocation />
      </Stack>
    );
  }

  return Widget;
};

export { LoyaltyWidget, LoyaltyNotAvailableAtLocation };
