import Button, { ButtonColor } from '@swe/shared/ui-kit/components/button';
import { InfoIcon } from '@swe/shared/ui-kit/components/icon';
import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';

import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import cx from 'clsx';

import styles from './styles.module.scss';

import { WidgetLeftPanel } from 'domains/profile/containers/loyalty/components/loyalty-widget/components/current-level';
import { WidgetRightPanel } from 'domains/profile/containers/loyalty/components/loyalty-widget/components/next-level';
import { PointsExpiration } from 'domains/profile/containers/loyalty/components/loyalty-widget/components/points-expiration';
import { LoyaltyProgressBar } from 'domains/profile/containers/loyalty/components/loyalty-widget/components/progress-bar';
import { LoyaltyProgressSkeleton } from 'domains/profile/containers/loyalty/components/loyalty-widget/components/skeleton';
import { StatusAndPoints } from 'domains/profile/containers/loyalty/components/loyalty-widget/components/status-and-points';
import { LoyaltyInfo, Status } from 'entities/loyalty/info';
import { findLoyaltyLevelSiblingsById, LoyaltySettings } from 'entities/loyalty/settings';

type LoyaltyWidgetProps = ComponentHasClassName & {
  status: Status;
  source: LoyaltyInfo | null;
  settings: LoyaltySettings;
  onInfoButtonClick?: () => void;
  hideCurrency?: boolean;
};

const LoyaltyWidget = ({
  className,
  status,
  source,
  settings,
  onInfoButtonClick,
  hideCurrency,
}: LoyaltyWidgetProps) => {
  const theme = useTheme();
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;

  if (!settings.enabled || !source) return null;

  const {
    balance: { balance: earnedAmount },
    progress: { nextLoyaltyId, nextGoal },
    score,
    pointExpirationInfo,
  } = source;

  const { previous: currentLevel, searched: nextLevel } = findLoyaltyLevelSiblingsById(settings, nextLoyaltyId);

  if (!currentLevel) return null;

  return (
    <div className={cx(className, styles.root)}>
      {status === Status.Member && (
        <div className={styles.header}>
          <div className={cx(styles.title, styles.topLeft)}>
            {nextYear} <br /> Status
          </div>
          {onInfoButtonClick && (
            <Button
              className={styles.topRight}
              color={theme.loyalty.buttonInfo as ButtonColor}
              size="sm"
              icon={InfoIcon}
              onClick={onInfoButtonClick}
              ariaLabel="Loyalty detail"
            />
          )}
          <LoyaltyProgressBar
            className={styles.progressBar}
            logo={settings.logoUrl}
            value={nextGoal ? (score ?? earnedAmount) / nextGoal : 100}
          />
          <WidgetLeftPanel
            className={styles.bottomLeft}
            earnedAmount={score ?? earnedAmount}
            status={nextLevel ? currentLevel.name : undefined}
          />
          <WidgetRightPanel
            className={styles.bottomRight}
            hasNextLevel={!!nextLevel}
            status={nextLevel ? nextLevel.name : currentLevel.name}
          />
        </div>
      )}
      <div>
        <StatusAndPoints
          className={styles.statusAndProperty}
          currentStatus={currentLevel?.name}
          earnedAmount={earnedAmount}
          currentYear={currentYear}
          pointToMoneyRate={currentLevel?.bonusToMoney}
          hideCurrency={hideCurrency}
        />
        {pointExpirationInfo && <PointsExpiration data={pointExpirationInfo} />}
      </div>
      {status === Status.Member && (
        <div>
          If the {nextYear} progress level is higher than your current level, then the current level will equal the{' '}
          {nextYear} progress level
        </div>
      )}
    </div>
  );
};

const LoyaltyWidgetSkeletonized = withSkeleton(LoyaltyWidget, LoyaltyProgressSkeleton, [
  'status',
  'source',
  'settings',
]);

export { LoyaltyWidget, LoyaltyWidgetSkeletonized };
