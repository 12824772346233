import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { formatPoints } from '@swe/shared/utils/points';

import styles from 'domains/profile/containers/loyalty/components/loyalty-widget/styles.module.scss';

type WidgetLeftPanelProps = ComponentHasClassName & {
  earnedAmount?: number;
  status?: string;
};

const WidgetLeftPanel = ({ className, earnedAmount, status }: WidgetLeftPanelProps) => {
  return (
    <div className={className}>
      {typeof earnedAmount !== 'undefined' && <>{formatPoints(earnedAmount)} earned</>}
      {status && <div className={styles.title}>{status}</div>}
    </div>
  );
};

export type { WidgetLeftPanelProps };
export { WidgetLeftPanel };
