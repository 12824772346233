/* eslint-disable no-bitwise */
import { Trans } from '@swe/shared/tools/intl/components/trans';
import Text, { TextProps } from '@swe/shared/ui-kit/components/text';

import { useMemo } from 'react';

import { Requirement } from 'entities/loyalty/info';

type RequirementsTextProps = {
  requirements?: number | null;
} & TextProps<any>;

const RequirementsText = ({ requirements, ...props }: RequirementsTextProps) => {
  const text = useMemo(
    () => ({
      [Requirement.Phone]: (
        <Trans
          id="domains.profile.loyalty.requirements.phoneNumber"
          defaultMessage="Provide your phone number in your Contact Information to activate the Loyalty Program"
        />
      ),
      [Requirement.NotificationSubscription]: (
        <Trans
          id="domains.profile.loyalty.requirements.notificationsSubscription"
          defaultMessage="Subscribe to our marketing messages in your Notifications to activate the Loyalty Program"
        />
      ),
      [Requirement.Phone | Requirement.NotificationSubscription]: (
        <Trans
          id="domains.profile.loyalty.requirements.phoneNumberAndNotificationSubscription"
          defaultMessage="Provide your phone number in your Contact Information and subscribe to marketing messages in your Notifications to activate the Loyalty Program"
        />
      ),
    }),
    [],
  );

  if (!requirements) return null;

  return <Text {...props}>{text[requirements]}</Text>;
};

export type { RequirementsTextProps };
export { RequirementsText };
